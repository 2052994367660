import React, { useEffect, useRef, useState } from 'react';
import { CartIcon } from "../../svgs";


interface cartAnimateProps {
    itemCount: number;
    handleClick: () => void;
}
  

const CartButton: React.FC<cartAnimateProps> = ({itemCount, handleClick}) => {
    const [cartShake, setCartShake] = useState(false);

    const checkoutBtnRef = useRef("null");

    const ref = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        ;
    }, [cartShake]);

    const classes = `md:block cart-container ${cartShake? "shake": ""}`;
 
    return (
        <div className={classes} ref={ref}>
            <button className="block flex flex-inline gap-3 text-gray-900 hover:text-gray-600" onClick={()=> handleClick()}>
            <span className="text-gray-900"><CartIcon /></span> Cart {(itemCount > 0) ? `(${itemCount})` : ""}</button>
        </div>
    );
  }
  
export default CartButton;