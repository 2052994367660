import React from "react";
import '../styles/WebContentTexts.scss';

const CareInstructionPage = () => {
    const care = [
        { title: "Snuffle Bols",
        desc: `As the bols are lovingly handcrafted to the highest attention to detail possible, it is recommended that they are hand washed.\n 
        Simply shake & remove all pieces of food before soaking the bol in diluted detergent / pet safe fabric cleaner. Rinse a few more times until water runs clear of soap.\n 
        Gently squeeze the bol dry without twisting it. Hang the bol out to dry in the sun if possible. To speed the drying process up, ensure the bol is aired in a well ventilated space.\n 
        If you choose to use a washing machine, please ensure that it is on the delicate setting, cold water, and zero to lowest spin dry setting possible. DO NOT use a dryer for the bols.\n
        For daily maintenance, ensure that all pieces of food are removed after a session of snuffling. Keep the bol in a well ventilated space. It is ideal to sun the bol after each session if possible.\n
        Contact us if you have any questions.`,
        },
        { title: "Snuffle Mats",
        desc: `Snuffle Mats are NOT machine washable because of the plastic base.
        To clean the Snuffle Mat, shake & remove all pieces of food before soaking it in diluted detergent / pet safe fabric cleaner. Rinse a few more times until water runs clear of soap.\n
        Gently squeeze the fabric of the mat dry without twisting it. 
        Hang it out to dry in the sun if possible. To speed the drying process up, ensure that the mat is aired in a well ventilated space. DO NOT use a dryer. \n    
        For daily maintenance, ensure that all pieces of food are removed after a session of snuffling. Keep the mat in a well ventilated space. It is ideal to sun the mat after each session, if possible. \n
        Contact us if you have any questions.`,
        }];
    return (
        <section className="px-8 py-20">
          <div className="container mx-auto">
            <div className="mb-14 text-center ">
              <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">
                Frequently asked questions
              </h1>
            </div>
            <div className="max-w-3xl mx-auto grid gap-10">
              {care.map(({ title, desc }) => (
                <div key={title}>
                  <p className="pt-0 pb-6 text-[20px] font-bold">
                    {title}
                  </p>
                  <div className="border-t border-gray-200 pt-4">
                    {desc.split('\n\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <p className="font-normal !text-gray-800 mb-3">
                                {line.split("\n").map(function(item, idx) {
                                    return (<span key={idx}>{item} <br/></span>
                                    )
                                })}</p>
                        </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    )
}

export default CareInstructionPage