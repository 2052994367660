import { InvoiceRequestBody } from "../models/Cart";
import { API_ENDPOINT } from './index'

export async function fetchInvoiceById(id: number): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/find_invoice/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch invoice');
        }
        return response.json();
    } catch (error) {
        console.error('Error fetching invoice:', error);
        throw error;
    }
}

export async function fetchInvoiceByUserId(id: number, token: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/${id}/find_invoice`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch invoice');
        }
        return response.json();
    } catch (error) {
        console.error('Error fetching invoice:', error);
        throw error;
    }
}

export async function createInvoice(cart: InvoiceRequestBody, token: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/create_invoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(cart)
        })

        return await response.json()
    } catch (error) {
        console.error('Error creating invoice: ', error)
        throw error
    }
}

export async function fetchPromoCode(promoCode: string, token: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/promocode/${promoCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        return await response.json()
    } catch (error) {
        console.error('Error Finding Promo code: ', error)
        throw error
    }
}