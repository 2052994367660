import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import cartReducer from './features/cartSlice';
import checkoutReducer from './features/checkoutSlice';
import invoiceReducer from './features/invoiceSlice';
import userReducer from './features/userSlice';
import productReducer from './features/productSlice';
import colorReducer from './features/colorSlice';


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        user: userReducer,
        checkout: checkoutReducer,
        invoices: invoiceReducer,
        products: productReducer,
        colors: colorReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

