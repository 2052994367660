import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Color } from "../models/Color";
import { fetchColors } from "../api/ColorService";

interface ColorState {
    data: Color[];
    loadingByColor: boolean;
    error: string | null;
}

const initialState: ColorState = {
    data: [],
    loadingByColor: true,
    error: null
}

export const fetchColorsAsync = createAsyncThunk(
    'colors/fetchColors',
    async () => {
        const response = await fetchColors()
        return response
    }
)
const clearAction = createAction('clear')

const colorSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchColorsAsync.pending, (state) => {
                state.loadingByColor = true;
                state.error = null
            })
            .addCase(fetchColorsAsync.fulfilled, (state, action) => {
                ;
                state.loadingByColor = false;
                state.data = action.payload;
            })
            .addCase(fetchColorsAsync.rejected, (state, action) => {
                state.loadingByColor = true;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            .addCase(clearAction, () => initialState)
    }
})

export default colorSlice.reducer;

export const selectProductTypeLoading = (state: RootState) => state.products.loadingByType