import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch, store } from '../store';
import "../styles/UserDetailsForm.scss";
import { setFirstName, setLastName, setEmail, setPostalCode, setCountry, setStreetAddress, setPhoneNumber, setPetIG } from "../features/checkoutSlice";
import TuiInput from "../components/inputs/TuiInput";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import Radio from '@mui/material/Radio';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip, Button } from "@material-tailwind/react";

interface CheckoutPageProps {
    handlePayNow: () => void; // Define prop type for handlePayNow function
}

const UserDetailsForm: React.FC<CheckoutPageProps> = ({ handlePayNow }) => {
    const isMobile = useMediaQuery('(max-width:1000px)');
    const dispatch = useDispatch<AppDispatch>()
    const firstName = useSelector((state: RootState) => state.checkout.firstName);
    const lastName = useSelector((state: RootState) => state.checkout.lastName);
    const email = useSelector((state: RootState) => state.checkout.email);
    const postalCode = useSelector((state: RootState) => state.checkout.postalCode);
    const petIG = useSelector((state: RootState) => state.checkout.petIG);
    const streetAddress = useSelector((state: RootState) => state.checkout.streetAddress);
    const phoneNumber = useSelector((state: RootState) => state.checkout.phoneNumber);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPostalCode, setIsValidPostalCode] = useState(true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [disableButtonFlag, setDisableButtonFlag] = useState(true)
    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setFirstName(value))
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setLastName(value))
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setEmail(value))
        const isValid = validateEmail(value); // Implement your email validation logic
        setIsValidEmail(isValid);
    };

    const validateEmail = (email: string) => {
        // Basic email validation using a regular expression
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isValid = /^\d{6}$/.test(value);
        
        setIsValidPostalCode(isValid)
        dispatch(setPostalCode({
            postalCode: value,
            isValid: isValid
        }))
        
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setCountry(value))
    };

    const handleStreetAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setStreetAddress(value))
    };

    const handlePetIGChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        dispatch(setPetIG(value))
    }

    const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const isValid = /^\d{8}$/.test(value);
        setIsValidPhoneNumber(isValid)
        dispatch(setPhoneNumber(value))
    }

    const [selectedValue, setSelectedValue] = React.useState('cc');


    useEffect(() => {
        const shouldEnableButton = email && phoneNumber && streetAddress && postalCode &&
                               isValidEmail && isValidPhoneNumber && streetAddress.trim() !== "" && isValidPostalCode;
        setDisableButtonFlag(!shouldEnableButton)
    }, [isValidEmail, isValidPhoneNumber, streetAddress, isValidPostalCode])

    return (
        <div>
            <div>
            <h6 className="text-xl font-semibold mb-2">Contact</h6>
            <TuiInput  label={"Email"} helperText={!isValidEmail ? "Invalid Email Address" : ""} isError={!isValidEmail ? true : false} value={email} onChange={handleEmailChange} />

            <h6 className="text-xl font-semibold mb-2">Delivery Details</h6>
            <div className="md:flex md:gap-2">
                <TuiInput width="md:basis-1/2" label={"First name"} value={firstName} onChange={handleFirstNameChange} />
                <TuiInput width="md:basis-1/2"  label={"Last name"} value={lastName} onChange={handleLastNameChange} />
            </div>
            <TuiInput  label={"Street address"} value={streetAddress} onChange={handleStreetAddressChange} />
            <TuiInput  label={"Postal code"} value={postalCode.postalCode} helperText={!isValidPostalCode ? "Invalid Postal Code" : ""} isError={!isValidPostalCode ? true : false} onChange={handlePostalCodeChange} />
            <TuiInput  label={"Phone number"} value={phoneNumber} helperText={!isValidPhoneNumber ? "Invalid Phone Number" : ""} isError={!isValidPhoneNumber ? true : false} onChange={handlePhoneNumber} />
            <TuiInput  label={"Pet Instagram (Optional)"} value={petIG} onChange={handlePetIGChange} />
            </div>

            <div className="mt-10 grow">
                <div className="credit-card-container text-sm my-3 text-center">
                    By clicking "Proceed to Payment" below, you will receive an email with invoice and payment instructions.
                </div>
                <Button disabled={disableButtonFlag}
                children={<>Proceed to Payment</>} onClick={handlePayNow} placeholder="" fullWidth />
            </div>
        </div>
    );
};

export default UserDetailsForm;
