import React from "react";
import '../styles/HeaderContainer.scss';
import NavBar from "./NavBar";
import Banner from "./Banner";

const HeaderContainer = () => {
    return (
        <div className="header-wrapper">
            <Banner />
            <NavBar />
        </div>
    )
}

export default HeaderContainer