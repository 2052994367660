import { createSlice, createAsyncThunk, PayloadAction, createAction } from '@reduxjs/toolkit';
import { PaymentRequestBody, PaymentResponseBody, HitPayInvoiceRequest } from '../models/Payment';
import { createPayment, createHitPayInvoice } from '../api/PaymentService';
import { RootState } from '../store';

interface CheckoutState {
    firstName: string;
    lastName: string;
    email: string;
    postalCode: PostalCodeState;
    country: string;
    petIG: string;
    streetAddress: string;
    phoneNumber: string;
    loadingCreatePayment: boolean;
    loadingCreateInvoice: boolean
    error: string | null
    data: PaymentResponseBody | null;
}

interface PostalCodeState {
    postalCode: string;
    isValid: boolean;
}

const initialState: CheckoutState = {
    firstName: '',
    lastName: '',
    email: '',
    petIG: '',
    postalCode: {
        postalCode: '',
        isValid: false
    },
    country: '',
    streetAddress: '',
    phoneNumber: '',
    loadingCreatePayment: true,
    loadingCreateInvoice: true,
    error: null,
    data: null
};

export const createPaymentAsync = createAsyncThunk(
    'checkout/createPayment',
    async (payment: { paymentRequest: PaymentRequestBody }) => {
        const response = await createPayment(payment.paymentRequest)
        return response
    }
);

export const createHitPayInvoiceAsync = createAsyncThunk(
    'checkout/createHitPayInvoice',
    async (payment: { invoiceRequest: HitPayInvoiceRequest }) => {
        const response = await createHitPayInvoice(payment.invoiceRequest)
        return response
    }
);

const clearAction = createAction('clear')

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setFirstName(state, action: PayloadAction<string>) {
            state.firstName = action.payload;
        },
        setLastName(state, action: PayloadAction<string>) {
            state.lastName = action.payload;
        },
        setPetIG(state, action: PayloadAction<string>) {
            state.petIG = action.payload
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setPostalCode(state, action: PayloadAction<PostalCodeState>) {
            state.postalCode = action.payload
        },
        setCountry(state, action: PayloadAction<string>) {
            state.country = action.payload
        },
        setStreetAddress(state, action: PayloadAction<string>) {
            state.streetAddress = action.payload
        },
        setPhoneNumber(state, action: PayloadAction<string>) {
            state.phoneNumber = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPaymentAsync.pending, (state) => {
                state.loadingCreatePayment = true;
                state.error = null;
            })
            .addCase(createPaymentAsync.fulfilled, (state, action) => {
                state.loadingCreatePayment = false;
                state.data = action.payload
            })
            .addCase(createPaymentAsync.rejected, (state, action) => {
                state.loadingCreatePayment = false;
                state.error = action.error.message || 'Failed to create payment';
            })
            .addCase(createHitPayInvoiceAsync.pending, (state) => {
                state.loadingCreateInvoice = true;
                state.error = null;
            })
            .addCase(createHitPayInvoiceAsync.fulfilled, (state, action) => {
                state.loadingCreateInvoice = false;
                state.data = action.payload
            })
            .addCase(createHitPayInvoiceAsync.rejected, (state, action) => {
                state.loadingCreateInvoice = false;
                state.error = action.error.message || 'Failed to create invoice';
            })
            .addCase(clearAction, () => initialState)
    }
});

export const { setFirstName, setLastName, setEmail, setPetIG, setPostalCode, setCountry, setStreetAddress, setPhoneNumber } = checkoutSlice.actions;

export const paymentData = (state: RootState) => state.checkout.data;
export const paymentLoading = (state: RootState) => state.checkout.loadingCreatePayment;
export const invoiceLoading = (state: RootState) => state.checkout.loadingCreateInvoice;

export default checkoutSlice.reducer;
