import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { login, logout } from "../api/LoginService";
import { jwtDecode } from "jwt-decode";

interface AuthState {
    token: string | null;
    userId: string | null;
    loading: boolean;
    error: string;
    loginState: boolean;
}

const initialState: AuthState = {
    token: localStorage.getItem('token'),
    userId: localStorage.getItem('userId'),
    loading: false,
    error: "",
    loginState: false
};

const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        // Add other synchronous actions here if needed
    },
    extraReducers: (builder) => {
        builder
            // Add reducers for the login thunk action
            .addCase(LoginUser.pending, (state) => {
                state.loading = true;
                state.error = "";
                state.loginState = false
            })
            .addCase(LoginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload;
                state.loginState = true
                localStorage.setItem('token', action.payload);
                if (state.token) {
                    const decoded: any = jwtDecode(state.token);
                    
                    // Extract the user ID from the decoded token
                    const userId = decoded.user_id;
                    localStorage.setItem('userId', userId);
                    
                }
            })
            .addCase(LoginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
                state.loginState = false
            })
            .addCase(LogoutUser.pending, (state) => {
                state.error = "";
                state.loginState = false
            })
            .addCase(LogoutUser.fulfilled, (state, action) => {
                
                state.loginState = false
                state.token = ""
                
            })
            .addCase(LogoutUser.rejected, (state, action) => {
                state.error = action.payload as string;
                state.loginState = false
            });
    }
});

// Define the login thunk action creator
export const LoginUser = createAsyncThunk(
    'auth/login',
    async (credentials: { username: string; password: string }, { rejectWithValue }) => {
        try {
            const response = await login(credentials.username, credentials.password);
            
            return response.token;
        } catch (error) {
            if (error instanceof Error) {
                // Now TypeScript knows that 'error' is an instance of 'Error'
                console.error(error.message);
            } else {
                // Handle other cases
                console.error('An unknown error occurred');
            }
        }
    }
);

export const LogoutUser = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
            const response = await logout();
            
            localStorage.removeItem('token')
            localStorage.removeItem('userId')
            return response;
        } catch (error) {
            
            if (error instanceof Error) {
                // Now TypeScript knows that 'error' is an instance of 'Error'
                console.error(error.message);
            } else {
                // Handle other cases
                console.error('An unknown error occurred');
            }
        }
    }
);


export const { } = authSlice.actions;

export default authSlice.reducer;

export const selectToken = (state: RootState) => state.auth.token;
export const userId = (state: RootState) => state.auth.userId;
export const loginState = (state: RootState) => state.auth.loginState;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectError = (state: RootState) => state.auth.error;