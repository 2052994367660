import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const initialState: UserState = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setFirstName(state, action: PayloadAction<string>) {
            
            
            state.firstName = action.payload;
        },
        setLastName(state, action: PayloadAction<string>) {
            
            
            state.lastName = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            
            
            state.email = action.payload;
        },
        setPassword(state, action: PayloadAction<string>) {
            
            
            state.password = action.payload;
        },
        register(state) {
            // Here you can perform any login logic, such as sending a request to the server
            // For demonstration purposes, let's just set isLoggedIn to true if username and password are not empty
            
            
            
            
            
        },
    },
});

export const { setFirstName, setLastName, setEmail, setPassword, register } = userSlice.actions;

export default userSlice.reducer;
