import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState } from '../store';
import { CartItem, Product } from '../common/types';

interface CartState {
    items: CartItem[];
    totalPrice: number;
    totalPricePostCalculation: number;
    discountAmount: string;
    discount: number;
}

const initialState: CartState = {
    items: [],
    totalPrice: 0,
    totalPricePostCalculation: 0,
    discountAmount: "0",
    discount: 0
};

export const clearAction = createAction('clear')

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateTotalPrice: (state, action: PayloadAction<number>) => {
            state.totalPricePostCalculation = action.payload
        },
        addToCart: (state, action: PayloadAction<CartItem>) => {
            const { id, quantity, colors, name, type } = action.payload;
            // const existingItem = state.items.find(item => item.id === id);
            const existingItemIndex = state.items.findIndex(item =>
                item.id === id &&
                item.colors.slice().sort().join(',') === colors.slice().sort().join(',') && // Check if colors are the same
                item.name === name && // Check if name is the same
                item.type === type // Check if type is the same
            );
            
            if (existingItemIndex !== -1) {
                
                state.items[existingItemIndex].quantity += quantity;
            } else {
                
                state.items.push(action.payload);
            }
        },
        removeFromCart(state, action: PayloadAction<CartItem>) {
            const item = action.payload;
            state.items = state.items.filter(cartItem =>
                cartItem.id !== item.id ||
                cartItem.colors.slice().sort().join(',') !== item.colors.slice().sort().join(',') || // Check if colors are the same
                cartItem.name !== item.name || // Check if name is the same
                cartItem.type !== item.type || // Check if type is the same
                cartItem.description !== item.description ||
                cartItem.size !== item.size
            );
        },
        clearCart: state => {
            state.items = [];
            state.totalPrice = 0
            state.totalPricePostCalculation = 0
        },
        getTotalPrice: state => {
            let totalPrice = 0
            state.items.forEach(item => {
                totalPrice += item.price * item.quantity
            })
            state.totalPrice = totalPrice
        },
        setDiscount(state, action: PayloadAction<number>) {
            state.discount = action.payload
        },
        updateQuantity(state, action: PayloadAction<{ foundItem: CartItem; quantity: number, fromInput: boolean }>) {
            const { foundItem, quantity, fromInput } = action.payload;
            const itemInState = state.items.find(item => item.id === foundItem.id &&
                item.colors.slice().sort().join(',') === foundItem.colors.slice().sort().join(',') &&
                item.name === foundItem.name);
            
            
            if (itemInState) {
                if (fromInput) {
                    itemInState.quantity = quantity
                } else {
                    itemInState.quantity += quantity;
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(clearAction, () => initialState)
    },
});

export const { addToCart, removeFromCart, clearCart, updateQuantity, getTotalPrice, updateTotalPrice, setDiscount } = cartSlice.actions;

export const selectCartItems = (state: RootState) => state.cart.items;
export const totalPrice = (state: RootState) => state.cart.totalPrice;

export default cartSlice.reducer;
