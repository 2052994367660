import { API_ENDPOINT } from './index';

export async function fetchProductByType(productType: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/product/type/${productType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch products')
        }
        return response.json();
    } catch (error) {
        console.error("Error Fetching Products: ", error);
        throw error;
    }
}

export async function fetchProductByItem(productItem: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/product/item/${productItem}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch products')
        }
        return response.json();
    } catch (error) {
        console.error("Error Fetching Products: ", error);
        throw error;
    }
}

export async function fetchProductByName(productName: string): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/product/name/${productName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch products')
        }
        return response.json();
    } catch (error) {
        console.error("Error Fetching Products: ", error);
        throw error;
    }
}