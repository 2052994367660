import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CartPage from "./CartPage";
import LoginPage from "./Login";
import RegisterPage from './Register';
import CheckoutPage from "./Checkout/Checkout";
import InvoicePage from "./InvoicePage";
import ProductSelectionPage from "./ProductSelection";
import ProductTypeSelectionPage from "./ProductTypeSelection";
import CareInstructionPage from "./CareInstructionPage";
import DisclaimerPage from "./DisclaimerPage";
import OurStoryPage from "./OurStoryPage";
import HomePage from "./HomePage";
import TermsPage from "./TermsPage";
import FaqPage from "./FaqPage";
import ColorPage from "./ColorPage";

interface MainContainerProps {
    className?: string
}

const MainContainer: React.FC<MainContainerProps> = ({className}) => {
    return (
        <div className={`${className || ''}`}>
            <Routes>
                <Route path="/snuffle/customise/:productItem" element={<ProductSelectionPage fromTypeSelection={true} productType="" />} />
                {/* <Route path="/snufflemat/customise" element={<ProductSelectionPage fromTypeSelection={false} productType='Snuffle Mat' />} />
                <Route path="/snufflepom/customise" element={<ProductSelectionPage fromTypeSelection={false} productType='Snuffle Pom' />} /> */}
                <Route path="/play/customise/:productItem" element={<ProductSelectionPage fromTypeSelection={true} productType=""/>} />
                <Route path="/play" element={<ProductTypeSelectionPage productType="Play" />} />
                <Route path="/snuffle" element={<ProductTypeSelectionPage productType="Snuffle" />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/invoice/:invoice_id" element={<InvoicePage />} />
                <Route path="/careinstructions" element={<CareInstructionPage />} />
                <Route path="/disclaimer" element={<DisclaimerPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/colorchart" element={<ColorPage />} />
                <Route path="/termsandcondition" element={<TermsPage />} />
                <Route path="/ourstory" element={<OurStoryPage />} />
                <Route path="/" element={<HomePage />} />
                {/* Add more routes as needed */}
            </Routes>
        </div>
    )

}

export default MainContainer