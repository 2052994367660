import { API_ENDPOINT } from './index';

export async function fetchColors(): Promise<any> {
    try {
        const response = await fetch(`${API_ENDPOINT}/colors`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return await response.json()
    } catch (error) {
        console.error('Error creating colors: ', error)
        throw error
    }
}