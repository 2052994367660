import { createSlice, createAsyncThunk, PayloadAction, createAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchInvoiceById, fetchInvoiceByUserId, createInvoice, fetchPromoCode } from '../api/InvoiceService';
import { InvoiceRequestBody } from "../models/Cart";
import { HitPayCustomer, HitPayCustomerResponse } from '../models/Payment';
import { createHitPayCustomer } from '../api/PaymentService';

interface Invoice {
    ID: number;
    UserId: number;
    TotalAmount: number;
    CreatedAt: string;
    Description: string;
}

interface CreateInvoiceResponse {
    invoice_id: string;
    customer_email: string;
    customer_name: string;
    invoice_items: CreateInvoiceItemsResponse[];
}

export interface CreateInvoiceItemsResponse {
    additional_info: string;
    color: string[];
    color_string: string;
    level: string;
    price: number;
    product_id: number;
    quantity: number;
    size: string;
    product_name: string;
    product_type: string;
}

interface InvoiceState {
    data: Invoice[];
    loading: boolean;
    loadingById: boolean; // Loading state for fetching by ID
    loadingByUserId: boolean; // Loading state for fetching by user ID
    loadingCreateInvoice: boolean; // Loading state for creating invoice
    loadingPromoCode: boolean;
    loadingCreateHitpayCustomer: boolean;
    promoCodeIsValid: boolean;
    promoCodeValue: number;
    promoCodeString: string;
    promoCodeType: string;
    error: string | null;
    createInvoiceResponse: CreateInvoiceResponse | null;
    createHitPayCustomerResponse: HitPayCustomerResponse | null;
}

const initialState: InvoiceState = {
    data: [],
    loading: true,
    loadingById: true, // Loading state for fetching by ID
    loadingByUserId: true, // Loading state for fetching by user ID
    loadingCreateInvoice: true, // Loading state for creating invoice
    loadingPromoCode: true,
    loadingCreateHitpayCustomer: true,
    promoCodeIsValid: false,
    promoCodeValue: 0,
    promoCodeString: "",
    promoCodeType: "",
    error: null,
    createInvoiceResponse: null,
    createHitPayCustomerResponse: null
};

export const createHitPayCustomerAsync = createAsyncThunk(
    'invoices/createHitPayCustomer',
    async (customer: { customerRequest: HitPayCustomer;}) => {
        const response = await createHitPayCustomer(customer.customerRequest)
        return response
    }
)

export const createInvoiceAsync = createAsyncThunk(
    'invoices/createInvoice',
    async (cart: { cartRequest: InvoiceRequestBody; token: string }) => {
        const response = await createInvoice(cart.cartRequest, cart.token)
        return response
    }
)

export const fetchInvoiceByIdAsync = createAsyncThunk(
    'invoices/fetchById',
    async (invoice: { id: number }) => {
        const response = await fetchInvoiceById(invoice.id);
        
        return response;
    }
);

export const fetchInvoiceByUserIdAsync = createAsyncThunk(
    'invoices/fetchInvoiceByUserIdAsync',
    async (invoice: { id: number; token: string }) => {
        const response = await fetchInvoiceByUserId(invoice.id, invoice.token);
        
        return response;
    }
);

export const fetchPromoCodeAsync = createAsyncThunk(
    'invoices/fetchPromoCodeAsync',
    async (invoice: { promoCode: string, token: string }) => {
        const response = await fetchPromoCode(invoice.promoCode, invoice.token);
        
        return response;
    }
);

export const clearInvoiceAction = createAction('clear')

const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        // Add other synchronous actions here if needed
        UpdatePromoCodeIsValid: (state, action: PayloadAction<boolean>) => {
            state.promoCodeIsValid = action.payload
        },
        UpdatePromoCodeValue: (state, action: PayloadAction<number>) => {
            state.promoCodeValue = action.payload
        },
        UpdatePromoCodeString: (state, action: PayloadAction<string>) => {
            state.promoCodeString = action.payload
        },
        UpdatePromoCodeType: (state, action: PayloadAction<string>) => {
            state.promoCodeType = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch by ID
            .addCase(fetchInvoiceByIdAsync.pending, (state) => {
                state.loadingById = true;
                state.error = null;
            })
            .addCase(fetchInvoiceByIdAsync.fulfilled, (state, action) => {
                state.loadingById = false;
                state.data = action.payload;
            })
            .addCase(fetchInvoiceByIdAsync.rejected, (state, action) => {
                state.loadingById = false;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            // Fetch by user ID
            .addCase(fetchInvoiceByUserIdAsync.pending, (state) => {
                state.loadingByUserId = true;
                state.error = null;
            })
            .addCase(fetchInvoiceByUserIdAsync.fulfilled, (state, action) => {
                ;
                state.loadingByUserId = false;
                state.data = action.payload;
            })
            .addCase(fetchInvoiceByUserIdAsync.rejected, (state, action) => {
                state.loadingByUserId = false;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            //Create Invoice
            .addCase(createInvoiceAsync.pending, (state) => {
                state.loadingCreateInvoice = true;
                state.error = null;
            })
            .addCase(createInvoiceAsync.fulfilled, (state, action) => {
                ;
                state.loadingCreateInvoice = false;
                state.createInvoiceResponse = action.payload;
            })
            .addCase(createInvoiceAsync.rejected, (state, action) => {
                state.loadingCreateHitpayCustomer = false;
                state.error = action.error.message || 'Failed to fetch invoice';
            })
            // Create Hitpay customer
            .addCase(createHitPayCustomerAsync.pending, (state) => {
                state.loadingCreateHitpayCustomer = true;
                state.error = null;
            })
            .addCase(createHitPayCustomerAsync.fulfilled, (state, action) => {
                ;
                state.loadingCreateHitpayCustomer = false;
                state.createHitPayCustomerResponse = action.payload;
            })
            .addCase(createHitPayCustomerAsync.rejected, (state, action) => {
                state.loadingCreateHitpayCustomer = false;
                state.error = action.error.message || 'Failed to create hit pay customer';
            })
            //Find Promo Code
            .addCase(fetchPromoCodeAsync.pending, (state) => {
                state.loadingPromoCode = true;
                state.error = null;
            })
            .addCase(fetchPromoCodeAsync.fulfilled, (state, action) => {
                state.loadingPromoCode = false;
                state.data = action.payload;
            })
            .addCase(fetchPromoCodeAsync.rejected, (state, action) => {
                state.loadingPromoCode = false;
                state.error = action.error.message || 'Failed to fetch promo code';
            })
            .addCase(clearInvoiceAction, () => initialState);
    }
});

export const { UpdatePromoCodeIsValid, UpdatePromoCodeValue, UpdatePromoCodeString, UpdatePromoCodeType } = invoiceSlice.actions;

export default invoiceSlice.reducer;

export const selectInvoiceData = (state: RootState) => state.invoices.data;
export const selectInvoiceLoading = (state: RootState) => state.invoices.loadingById;
export const selectInvoiceByUserIdLoading = (state: RootState) => state.invoices.loadingByUserId;
export const selectInvoiceCreateLoading = (state: RootState) => state.invoices.loadingCreateInvoice;
export const selectInvoiceError = (state: RootState) => state.invoices.error;
export const createInvoiceResponseData = (state: RootState) => state.invoices.createInvoiceResponse;
