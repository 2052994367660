import React from "react";
import { Typography } from "@material-tailwind/react";

const Banner: React.FC = () => {
    return (
      <div className="shadow-sm px-4 py-2 flex bg-gray-900 flex-wrap items-center justify-center gap-x-6">
        <Typography color="white" className="text-sm" placeholder="">
          <em>Free tracked local doorstep shipping for orders above SGD80</em>
          </Typography>
      </div>
  );
}

export default Banner;