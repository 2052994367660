// Define the object type
interface KeyValueObject {
    [key: string]: string;
}

export const findKeyValuePair = <T extends KeyValueObject>(list1: T[], variable: string): T | undefined => {
    const foundInList = list1.find(obj => Object.values(obj).includes(variable));
    return foundInList
}

// Function to find key-value pair
export const findKeyValuePairInTwoList = (list1: KeyValueObject[], list2: KeyValueObject[], variable: string): KeyValueObject | undefined => {
    
    
    
    // Search in list1
    const foundInList1: KeyValueObject | undefined = list1.find(obj => Object.values(obj).includes(variable));
    if (foundInList1) return foundInList1;

    // Search in list2
    const foundInList2: KeyValueObject | undefined = list2.find(obj => Object.values(obj).includes(variable));
    if (foundInList2) return foundInList2;

    // If not found in both lists
    return undefined;
}

export const onlyEmptyStrings = (arr: string[]): boolean => {
    return arr.every(item => item === "");  // True if every element is an empty string
  }

  export function allInnerArraysEmptyStrings(arr: (string | object)[][]): boolean {
    return arr.every(innerArray => 
      innerArray.every(item => item === "")
    );
  }