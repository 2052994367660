import React from "react";
import "../styles/WebContentTexts.scss";

const DisclaimerPage = () => {
    const ColorsAndCustomisationText = [
        {
            title: 'Colors & Customisation',
            desc: `There may be slight differences in actual fabric colours due to screen resolution & digitalisation. Please refer to photos of sample products for a better idea of the colours on the products, or drop us a DM.
            Fabric colours may differ slightly due to batch differences, we seek your kind understanding while we do our best to maintain material quality & consistency.
            All available & unavailable colours will be updated on the order selection page.
            All colour charts displaying the full range of colour offerings posted on socials & website listings are for reference only.
            New or seasonal colours will be announced on socials & the website.
            Every product comes with a standard complimentary selection of up to 4 colours. Additional colours available @ SGD 5 each per product (up to a maximum of 7 colours per product only).`
        },
        {
            title: 'Products',
            desc: `We are dedicated to the highest levels of quality in our craft. Every product that reaches you has been carefully checked before shipping off to you. As we take immense pride in our products, please do not hesitate to reach out regarding any potential issues and problems faced. We will do our best to advise and troubleshoot for our products.
            As every product is fully customised and made to order, there will be strictly no returns and refunds. We seek your kind understanding.
            Please note that no toy is indestructible. It is your responsibility to ensure each snuffle session is supervised to minimise frustration in your pet, and to gradually increase snuffle difficulty according to your pet’s level of exposure to snuffling. Please do not hesitate to reach out to us if you have any questions regarding this.
            A frustrated pet may turn to chewing, pulling, shredding of the toys instead of using their sense of smell to search for treats hidden in the mats/bols. The Curious One will not be held liable for any damage caused by improper use (as aforementioned) of the products by you or your pet.
            As each product is meticulously handcrafted, it is highly encouraged that they are hand-washed to preserve the product’s quality and to ensure longevity of the product. Note that the products may come loose over time with regular use and multiple washes. This is normal wear & tear and should not affect product efficacy significantly.
            While we do our best to ensure a uniform standard for each product as far as possible, product colours and shape may sometimes differ slightly from photos posted on all socials and the website. This is due to the nature of fully customised handcrafted products.`
        }
    ]
    return (
        <section className="px-8 py-20">
          <div className="container mx-auto">
            <div className="mb-14 text-center ">
              <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">
                Frequently asked questions
              </h1>
            </div>
            <div className="max-w-3xl mx-auto grid gap-10">
              {ColorsAndCustomisationText.map(({ title, desc }) => (
                <div key={title}>
                  <p className="pt-0 pb-6 text-[20px] font-bold">
                    {title}
                  </p>
                  <div className="border-t border-gray-200 pt-4">
                    {desc.split('\n\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <p className="font-normal !text-gray-800 mb-3">
                                {line.split("\n").map(function(item, idx) {
                                    return (<span key={idx}>{item} <br/><br/></span>
                                    )
                                })}</p>
                        </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    )
}

export default DisclaimerPage