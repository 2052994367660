import { PaymentRequestBody, HitPayInvoiceRequest, HitPayCustomer } from "../models/Payment"
import { API_ENDPOINT } from './index';

export async function createPayment(cart: PaymentRequestBody): Promise<any> {
    try {
        
        const response = await fetch(`${API_ENDPOINT}/payment_requests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(cart)
        })

        return await response.json()
    } catch (error) {
        console.error('Error creating invoice: ', error)
        throw error
    }
}

export async function createHitPayInvoice(hitPayInvoiceObj: HitPayInvoiceRequest): Promise<any> {
    try {
        
        const response = await fetch(`${API_ENDPOINT}/hitpay_request/create_invoice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hitPayInvoiceObj)
        })

        return await response.json()
    } catch (error) {
        console.error('Error creating invoice: ', error)
        throw error
    }
}

export async function createHitPayCustomer(hitPayCustomerObj: HitPayCustomer): Promise<any> {
    try {
        
        const response = await fetch(`${API_ENDPOINT}/hitpay_request/create_customer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hitPayCustomerObj)
        })

        return await response.json()
    } catch (error) {
        console.error('Error creating invoice: ', error)
        throw error
    }
}