import React from "react";
import "../styles/TermsPage.scss";

const TermsPage = () => {
    const terms = [
        { title: "Terms and Conditions",
        desc: `Please read these terms and conditions ("terms and conditions", "terms") carefully before using "http://curiousone.co/" website (“website”, "service") operated by The Curious One ("us", 'we", "our").`,
        },
        { title: "Conditions of use",
        desc: "By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. The Curious One only grants use and access of this website, its products, and its services to those who have accepted its terms.",
        },
        { title: "Privacy policy",
        desc: "Before you continue using our website, we advise you to read our privacy policy 'http://curiousone.co/termsandcondition' regarding our user data collection. It will help you better understand our practices.",
        },
        { title: "Age restriction",
        desc: `You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. The Curious One assumes no responsibility for liabilities related to age misrepresentation.`,
        },
        { title: "Intellectual property",
        desc: `You agree that all materials, products, and services provided on this website are the property of The Curious One, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute The Curious One’s intellectual property in any way, including electronic, digital, or new trademark registrations. You grant The Curious One a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.`,
        },
        { title: "User accounts",
        desc: `As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly. We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.`,
        },
        { title: "Applicable law",
        desc: `By using this website, you agree that the laws of Singapore, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between The Curious One and you, or its business partners and associates.`
        },
        { title: "Disputes",
        desc: `Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by the State Courts of Singapore and you consent to exclusive jurisdiction and venue of such courts.`,
        },
        { title: "Indemnification",
        desc: `You agree to indemnify The Curious One and its affiliates and hold The Curious One harmless against legal claims and demands that may arise from you or your pet’s use or misuse of our services and products. We reserve the right to select our own legal counsel.`,
        },
        { title: "Limitation on liability",
        desc: `The Curious One is not liable for any damages that may occur to you or your pet as a result of your misuse of our website or product. The Curious One reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between The Curious One and the user, and this supersedes and replaces all prior agreements regarding the use of this website.`,
        }
    ]
    return (
        <section className="px-8 py-20">
          <div className="container mx-auto">
            <div className="mb-14 text-center ">
              <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">
                Frequently asked questions
              </h1>
            </div>
            <div className="max-w-3xl mx-auto grid gap-10">
              {terms.map(({ title, desc }) => (
                <div key={title}>
                  <p className="pt-0 pb-6 text-[20px] font-bold">
                    {title}
                  </p>
                  <div className="border-t border-gray-200 pt-4">
                    {desc.split('\n\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <p className="font-normal !text-gray-800 mb-3">
                                {line.split("\n").map(function(item, idx) {
                                    return (<span key={idx}>{item} <br/></span>
                                    )
                                })}</p>
                        </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    );
    // return (
    //     <section className="p-8">
    //         <div className="mx-auto max-w-screen-md">
    //             <h1>Terms and Conditions</h1>
    //             <p>Please read these terms and conditions ("terms and conditions", "terms") carefully before using "http://curiousone.co/" website (“website”, "service") operated by The Curious One ("us", 'we", "our").</p>

    //             <h2>Conditions of use</h2>
    //             <p>
    //                 By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. The Curious One only grants use and access of this website, its products, and its services to those who have accepted its terms.
    //             </p>

    //             <h2>Privacy policy</h2>
    //             <p>
    //                 Before you continue using our website, we advise you to read our privacy policy "http://curiousone.co/termsandcondition" regarding our user data collection. It will help you better understand our practices.
    //             </p>

    //             <h2>Age restriction</h2>
    //             <p>
    //                 You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. The Curious One assumes no responsibility for liabilities related to age misrepresentation.
    //             </p>

    //             <h2>Intellectual property</h2>
    //             <p>
    //                 You agree that all materials, products, and services provided on this website are the property of The Curious One, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute The Curious One’s intellectual property in any way, including electronic, digital, or new trademark registrations. You grant The Curious One a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.
    //             </p>

    //             <h2>User accounts</h2>
    //             <p>
    //                 As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly. We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.
    //             </p>

    //             <h2>Applicable law</h2>
    //             <p>
    //                 By using this website, you agree that the laws of Singapore, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between The Curious One and you, or its business partners and associates.
    //             </p>

    //             <h2>Disputes</h2>
    //             <p>
    //                 Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by the State Courts of Singapore and you consent to exclusive jurisdiction and venue of such courts.
    //             </p>

    //             <h2>Indemnification</h2>
    //             <p>
    //                 You agree to indemnify The Curious One and its affiliates and hold The Curious One harmless against legal claims and demands that may arise from you or your pet’s use or misuse of our services and products. We reserve the right to select our own legal counsel.
    //             </p>

    //             <h2>Limitation on liability</h2>
    //             <p>
    //                 The Curious One is not liable for any damages that may occur to you or your pet as a result of your misuse of our website or product. The Curious One reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between The Curious One and the user, and this supersedes and replaces all prior agreements regarding the use of this website.
    //             </p>
    //         </div>
    //     </section>
    // )
}

export default TermsPage