// apiService.ts
import { API_ENDPOINT } from './index';

export async function login(username: string, password: string): Promise<any> {
    try {
        
        const response = await fetch(`${API_ENDPOINT}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (!response.ok) {
            throw new Error('Failed to login');
        }

        return await response.json();
    } catch (error) {
        throw new Error('Failed to login');
    }
}

export async function logout(): Promise<any> {
    try {
        const token = localStorage.getItem('token')
        
        const response = await fetch(`${API_ENDPOINT}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token!
            },
        });

        
        return await response.json();
    } catch (error) {
        
        throw new Error('Failed to login');
    }
}
