import React, { useState, useEffect } from 'react';
import { Input, Typography } from "@material-tailwind/react";

type ValidVariant = 'text' | 'outlined' | 'contained';

interface TuiInputProps {
    label: string;
    value: string;
    isError?: boolean;
    width?: string;
    helperText?: string;
    onChange?: (newValue: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

const TuiInput: React.FC<TuiInputProps> = ({ label, value, helperText, isError = false, width = "w-full", onChange, onBlur }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnBlur = () => {
        if (onBlur) {
            onBlur()
        }
    }

    return (
        <div className={width}>
            <label className="block font-medium leading-6 text-gray-900 font-bold">{label}</label>
            <Input 
            crossOrigin={false}
            type="text"
            error={isError}
            value={value}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
            />
            <div className="mt-1 flex items-center gap-1 text-sm text-red-500 italic mb-4">
            {helperText}
            </div>
        </div>
    )
}

export default TuiInput