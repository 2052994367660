import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderContainer from "./components/HeaderContainer";
import MainContainer from './components/MainContainer';
import Footer from "./components/Footer";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <HeaderContainer />
          <MainContainer className="main-container grow"/>
          <Footer/>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
