import React, { useState } from 'react';
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { removeFromCart } from '../../features/cartSlice';
import '../../styles/ButtonQuantity.scss';
import { Select, Option, Button, Input } from "@material-tailwind/react";
import { SelectChangeEvent } from '@mui/material/Select';

interface CartQuantityProps {
    id: string;
    quantity: string;
    onChange: (quantity: number) => void;
}

const ButtonQuantity: React.FC<CartQuantityProps> = ({ id, quantity, onChange }) => {
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState<string>('');

/*    const handleChange = (e) => {
        const newQuantity = parseInt(e.target.value, 10);
        if (isNaN(newQuantity) || newQuantity < 1) {
            setErrorMessage('You can only add 1 item into your cart');
            onChange(1);
        } else {
            setErrorMessage('');
            onChange(newQuantity);
        }
    };

    const handleBlur = () => {
        if (quantity === 0) {
            onChange(1);
        }
    };*/

    const handleOptionSelect = (option: string) => {
        
        onChange(parseInt(option));
    }

    const handleChange = (e: SelectChangeEvent)=> {
        const newQuantity = parseInt(e.target.value, 10);
        if (isNaN(newQuantity) || newQuantity < 1) {
            setErrorMessage('You can only add 1 item into your cart');
            onChange(1);
        } else {
            setErrorMessage('');
            onChange(newQuantity);
        }
    };

    return (
        <div className="button-quantity-wrapper">
            <TextField
                fullWidth
                select
                value={quantity}
                onChange={(event) => handleOptionSelect(event.target.value)}
                size="small"
            >
                {
                    [...Array(10)].map((_, i) => i + 1)
                                .map(i => <MenuItem key={i} value={i.toString()}>{i}</MenuItem>)
                }
            </TextField>
        </div>
    )

/*    return (
        <div className="button-quantity-wrapper">
        <div className="relative flex w-full max-w-[10rem]">
            <Button className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 shadow-0 !shadow-none px-3"
            onClick={onDecrease}  placeholder="">
            -
            </Button>
            <Input type="number"
                placeholder=""
                className="input-quantity rounded-l-none rounded-r-none !border-t-blue-gray-200 focus:!border-t-gray-900 shadow-none bg-white"
                labelProps={{
                    className: "before:content-none after:content-none",
                }}
                containerProps={{
                    className: "min-w-0",
                }}
                value={quantity} onChange={handleChange}
                crossOrigin={undefined}      />
            <Button className="flex h-10 items-center gap-2 rounded-l-none border border-l-0 border-blue-gray-200 bg-blue-gray-500/10 !shadow-none px-3"
            onClick={onIncrease}  placeholder="">
            +
            </Button>
        </div>
        {errorMessage ? <div className="button-quantity-error-wrapper"><span className="button-quantity-error-text">{errorMessage}</span></div> : ""}
        </div>
    )

    return (
        <div className="button-quantity-wrapper">
            <div className="button-quantity-container">
                <button className="button-quantity" onClick={onDecrease}>-</button>
                <input className="input-quantity" type="number" value={quantity} onChange={handleChange} />
                <button className="button-quantity" onClick={onIncrease}>+</button>
            </div>
            {errorMessage ? <div className="button-quantity-error-wrapper"><span className="button-quantity-error-text">{errorMessage}</span></div> : ""}
        </div>
    );*/
};

export default ButtonQuantity;
