import React from "react";
import CareInstructionPage from "./CareInstructionPage";
import "../styles/FaqPage.scss";

const FaqPage = () => {
const faqs = [
{ title: "WHAT IS SNUFFLING?",
desc: `In short, snuffling is one form of enrichment for our pets (dogs, cats, even rats or rabbits!) to access species-appropriate challenges & stimulation. In snuffle toys and mats, treats are scattered and hidden all over for the pets to sniff out and eat.

Allowing our pets the opportunity for regular enrichment such as snuffling can help with their mental health and cognitive functions. Animals who are mentally healthy will be more confident, less easily stressed & become more exploratory of their environments.

Have you ever experienced having your pet ‘act out’ at home, showing ‘disruptive’/’destructive’ behaviours while you’re trying to work? Or perhaps your beloved pet just can’t seem to stop seeking out items so that they can destroy them? It’s as if the pets are trying to find their own fun, isn’t it? If that’s the case, it might be that your pet is just experiencing boredom or frustration at the insufficient mental stimulation! A range of type & difficulty levels in enrichment activities can help reduce frustration & boredom. In dogs, for instance, studies have shown that prolonged periods of sniffing lowers their heart rates & even cortisol levels. Because it is a natural & instinctual behaviour, most pets find it really rewarding! Throw in the joy of finding yummy treatos, it’s double the fun!`,
},
{ title: "HOW SHOULD I CARE FOR THE PRODUCTS?",
desc: "Please see the details of each product under Care Instructions. In general, all products (except the Snuffle Mat) can be machine washed with extra care. ",
},
{ title: "HOW DO I DECIDE WHICH SIZE / DIFFICULTY LEVEL TO CHOOSE?",
desc: "Typically, the size of the toys correspond to the size of the dog. A small dog for instance would probably prefer smaller toys. However, there are of course exceptions to the preferences of some dogs. If your dog is completely new to snuffling or using the Snuffle Bol for instance, we recommend a slow introduction process to help create a positive association with the Bol. When in doubt, please feel free to reach out to us!",
},
{ title: "WHAT IS THE SHIPPING PROCESS LIKE?",
desc: `All orders will be processed after full payment is received. 

As items are handcrafted, please note that the lead time will be 12-14 working days after full payment is received. During peak seasons, an additional 5 working days will be added to the lead time to account for order volume as well as courier’s availability.

Shipping fee: SGD 4
Free local tracked shipping for orders SGD 80 & above
International shipping coming soon`,
}
];

    return (
        <section className="px-8 py-20">
          <div className="container mx-auto">
            <div className="mb-14 text-center ">
              <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">
                Frequently asked questions
              </h1>
            </div>
            <div className="max-w-3xl mx-auto grid gap-10">
              {faqs.map(({ title, desc }) => (
                <div key={title}>
                  <p className="pt-0 pb-6 text-[20px] font-bold">
                    {title}
                  </p>
                  <div className="border-t border-gray-200 pt-4">
                    {desc.split('\n\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <p className="font-normal !text-gray-800 mb-3">
                                {line.split("\n").map(function(item, idx) {
                                    return (<span key={idx}>{item} <br/></span>
                                    )
                                })}</p>
                        </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
    );

    return (
        <div className="faq-wrapper">
            <h1>FAQ</h1>
            <h2>What is Snuffling?</h2>
            <div className="what-is-wrapper">
                <p>
                    In short, snuffling is one form of enrichment for our pets (dogs, cats, even rats or rabbits!) to access species-appropriate challenges & stimulation. In snuffle toys and mats, treats are scattered and hidden all over for the pets to sniff out and eat.

                    Allowing our pets the opportunity for regular enrichment such as snuffling can help with their mental health and cognitive functions. Animals who are mentally healthy will be more confident, less easily stressed & become more exploratory of their environments.

                    Have you ever experienced having your pet ‘act out’ at home, showing ‘disruptive’/’destructive’ behaviours while you’re trying to work? Or perhaps your beloved pet just can’t seem to stop seeking out items so that they can destroy them? It’s as if the pets are trying to find their own fun, isn’t it? If that’s the case, it might be that your pet is just experiencing boredom or frustration at the insufficient mental stimulation! A range of type & difficulty levels in enrichment activities can help reduce frustration & boredom. In dogs, for instance, studies have shown that prolonged periods of sniffing lowers their heart rates & even cortisol levels. Because it is a natural & instinctual behaviour, most pets find it really rewarding! Throw in the joy of finding yummy treatos, it’s double the fun!

                </p>
            </div>
            <h2>SHIPPING</h2>
            <div className="shipping-wrapper">
                <span>All orders will be processed after full payment is received.</span>
                <span>As items are handcrafted, please note that the lead time will be 12-14 working days after full payment is received. During peak seasons, an additional 5 working days will be added to the lead time to account for order volume as well as courier’s availability.</span> 
                <span>Shipping fee: SGD 4</span>
                <span>Free local tracked shipping for orders SGD 80 & above</span>
                <span>International shipping coming soon</span>
            </div>
            <h2>Care Instructions</h2>
            <CareInstructionPage />
        </div>
    )
}

export default FaqPage;