import React from "react";
import '../styles/WebContentTexts.scss';

const OurStoryPage = () => {
    return (
        <section className="p-8">
        <div className="mx-auto max-w-screen-md">
            <p className="!mt-4 text-base font-normal leading-[27px]">
                The Curious One was born out of a desire to spread the joy of being curious. We’re not just a micro business based in Singapore, we’re run by pet professionals deeply passionate about improving the quality of our lives with our companion pets.
            </p>
            <p className="!mt-4 text-base font-normal leading-[27px]">
                Experiencing firsthand how enrichment & snuffling has helped pets around us grow more confident & at ease, 
                </p>
            <p className="!mt-4 text-base font-normal leading-[27px]">
                That’s why we are on a mission to encourage more pet guardians to provide mental stimulation & enrichment for their pets as part of their lifestyle. Because we have also experienced firsthand how enrichment has helped build a more confident, at ease, & happy canine companion, we hope to spread the joy of enrichment to more pet guardians too. Happy pet, happy humans, happy lives!
                </p>
            <p className="!mt-4 text-base font-normal leading-[27px]">
                At The Curious One, you’ll find reasonably priced, high quality, lovingly crafted custom enrichment toys for pets of every level. Choose your type of enrichment toy, size, colours, and even difficulty level. What’s great about the signature snuffle bols at The Curious One is that they’re dense & customisable. Pets will find it a nice challenge while humans can choose colours & themes according to their unique tastes! Should you need any inspiration, we also have some set themes for you to choose from.
                </p>
            <p className="!mt-4 text-base font-normal leading-[27px]">
                Every pet starts somewhere, so if your pet is new to snuffling, there are beginner levels of snuffle bols for them. Snuffle mats are also a popular item to start the journey since it lays flat and is easily accessible. Just remember, enrichment should be stimulating, not frustrating, for your pet, so speak to us if you’re unsure if a specific toy is suitable for your pet. Either way, as long as you have a companion who enjoys sniffing things out & having yummy treats, you’ll be able to find something here. Otherwise, feel free to drop us a DM and we will see what we can do!
            </p>
        </div>
        </section>
    )
}

export default OurStoryPage