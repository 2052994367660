import React, { useState } from 'react';
import { Input, Button } from "@material-tailwind/react";


interface PromoCodeInputProps {
    promoCodeError: string;
    promoCode: string;
    onPromoCodeChange: (newValue: string) => void;
    onApplyPromoCode: () => void;
}

const PromoCodeInput: React.FC<PromoCodeInputProps> = ({ promoCodeError, promoCode, onPromoCodeChange, onApplyPromoCode }) => {

    const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onPromoCodeChange(e.target.value);
    };

    const handleApplyPromoCode = () => {
        onApplyPromoCode();
    };

    return (
        <div className="discount-wrapper flex my-10 gap-2">
            <Input
            error ={promoCodeError === "Invalid Promo Code" ? true : false}
            crossOrigin={"false"}
            onChange={handlePromoCodeChange}
            value={promoCode} 
            label="Discount code or gift card"
            className="bg-white rounded-md basis-2/3"
            variant="outlined"
            />
            <Button className="apply-promo-button basis-1/3" onClick={handleApplyPromoCode} placeholder="" color="black">Apply</Button>
        </div>
    );
};

export default PromoCodeInput;
