import React from "react";
import "../styles/ColorPage.scss"

const ColorPage = () => {
    return(
        <div className="color-container">
            <img src={"Curious one colours & pricing.jpg"} />
        </div>
    )
}

export default ColorPage