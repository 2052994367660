import React from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/Footer.scss';
import InstagramIcon from "../svgs/Instagram";
import TelegramIcon from "../svgs/Telegram";

const footerLinks = [
    {
        linkText: "About",
        uri: "/ourstory"
    },
    {
        linkText: "Color Chart",
        uri:"/colorchart"
    },
    {
        linkText: "Care Instructions",
        uri:"/careinstructions"
    },
    {
        linkText: "F.A.Q",
        uri: "/faq"
    },
    {
        linkText: "Disclaimer",
        uri: "/disclaimer"
    },
    {
        linkText: "T&Cs",
        uri: "/termsandcondition"
    }
];

interface NavItemPropsType {
    label: string;
    uri: string;
}

function NavItem({ label, uri }: NavItemPropsType) {
return (
    <li className="mx-2 md:mb-0 text-sm opacity-30 text-gray-50 hover:opacity-60">
        <a href={uri}>{label}</a>
    </li>
    
);
}

const currentYear = new Date().getFullYear();



const Footer: React.FC = () => {
    let navigate = useNavigate();
    const handleClick = (isExternal: boolean, toWhere: string) => {
        if (isExternal) {
            window.location.href = toWhere
        } else {
            navigate(toWhere);
        }
    } 
    return (
        <footer className="w-full bg-gray-900 montserrat p-4">
        <div className="mx-auto w-full max-w-7xl">
          <div className="flex flex-row items-center justify-center pt-0 lg:pt-2">
            <h5 className="flex-grow hidden md:inline-block"></h5>
            <ul className="flex xs:flex-row xs:justify-between">
                {footerLinks.map(({ linkText, uri }, key) => (
                    <NavItem key={key} label={linkText} uri={uri} />
                ))}
            </ul>
          </div>
          <div className="flex w-full flex-col items-center justify-center md:mt-4 md:flex-row md:justify-between md:border-t pt-4">
            <small className="mb-4 text-center text-xs text-gray-50 md:mb-0">
              &copy; {currentYear} <a href="https://curiousone.co/">the curious one</a>. All Rights Reserved.
            </small>
            <div className="flex gap-4 text-gray-50 sm:justify-center">
              <a href="https://www.instagram.com/curiousone.co" className="opacity-80 transition-opacity hover:opacity-100">
                  <InstagramIcon />
              </a>
              <a href="https://web.telegram.org/k/#@thecuriousco" className="opacity-80 transition-opacity hover:opacity-100">
                  <TelegramIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;
