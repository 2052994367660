import React, {useEffect, useState, useRef} from "react";
import {Navbar,Collapse,Typography,Button,IconButton,} from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { Link, useNavigate } from 'react-router-dom';
import { CartIcon } from "../svgs";
import CartButton from "./buttons/CartButton";
import '../styles/NavBar.scss';

// profile menu component
const productLinks = [
    {
        linkText: "snuffle",
        uri: "/snuffle"
    },
    {
        linkText: "play",
        uri: "/play"
    }
];


interface NavItemPropsType {
    label: string;
    uri: string;
}

export function NavbarWithSimpleLinks() {
    let navigate = useNavigate()

    function NavItem({ label, uri }: NavItemPropsType) {
        return (
            <li color="blue-gray" className="p-1 font-medium">
                <button onClick={() => handleListClick(uri)} className="text-gray-900 hover:text-gray-600">{label}</button>
            </li>
        );
    }
    
    function NavList() {
        return (
            <ul className="mb-4 mt-2 flex flex-col gap-3 md:mb-0 lg:mt-0 md:flex-row md:items-center md:gap-8">
                {productLinks.map(({ linkText, uri }, key) => (
                    <NavItem label={linkText} uri={uri} />
                ))}
        
            </ul>
        );
    }
    const handleListClick = (toWhere: string) => {
        navigate(toWhere);
    }

    const [open, setOpen] = React.useState(false);
    
    const handleOpen = () => setOpen((cur) => !cur);
    const cartItems = useSelector((state: RootState) => state.cart.items);

    React.useEffect(() => {
        window.addEventListener( "resize", () => window.innerWidth >= 960 && setOpen(false) );
    }, []);

    return (
        <Navbar color="transparent" fullWidth placeholder="">
        <div className="container mx-auto flex items-center justify-between text-blue-gray-900 montserrat">
            <a  onClick={() => handleListClick("/")} color="blue-gray" className="mr-4 cursor-pointer text-lg font-bold">
                <img className="img-home" src={'/assets/img/logo.png'} alt="the Curious One" />
            </a>
            <div className="hidden md:block">
                <NavList />
            </div>

            <IconButton placeholder=""
            size="sm"
            variant="text"
            color="blue-gray"
            onClick={handleOpen}
            className="ml-auto mr-4 inline-block text-blue-gray-900 md:hidden"
            >
            {open ? (
                "X"
            ) : (
                "="
            )}
            </IconButton>

            <CartButton itemCount={cartItems.length} handleClick={() => handleListClick("/cart")} />
        </div>
        <Collapse open={open}>
            <div className="mt-2 rounded-xl bg-white py-2 text-blue-gray-900 ">
            <NavList />
            </div>
        </Collapse>
        </Navbar>
    );
}

export default NavbarWithSimpleLinks;