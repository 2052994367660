import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { selectToken, userId } from "../features/authSlice";
import { useLocation, useParams } from "react-router-dom";
import { fetchInvoiceByIdAsync, selectInvoiceLoading, selectInvoiceCreateLoading } from "../features/invoiceSlice";
import { clearCart } from "../features/cartSlice";
import LoginPage from "./Login";
import "../styles/InvoicePage.scss";

const InvoicePage = () => {
    const { invoiceId } = useParams();
    const { search } = useLocation();
    const location = useLocation();

    // Parse query parameters from the search string
    const queryParams = new URLSearchParams(search);
    const reference = queryParams.get('reference');
    const status = queryParams.get('status');
    const dispatch = useDispatch<AppDispatch>()
    const isInvoiceLoading = useSelector(selectInvoiceLoading);
    const createdInvoiceLoading = useSelector(selectInvoiceCreateLoading)
    const [invoices, setInvoices] = useState<any[]>([]);
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(0);

    useEffect(() => {
        if (location.pathname) {
            const parts = location.pathname.split("/"); // Split the string by "/"
            const number = parts[2];
            const fetchInvoices = async (invoiceId: string) => {
                try {
                    const data = await dispatch(fetchInvoiceByIdAsync({ id: parseInt(invoiceId!) }));
                    
                    if (data.payload && data.payload.length !== 0) {
                        
                        setInvoices(data.payload);
                    }
                } catch (error) {
                    console.error('Error fetching invoices:', error);
                }
            };
            if (number) {
                fetchInvoices(number);
            }
        }
    }, []);

    useEffect(() => {
        if (createdInvoiceLoading) {
            dispatch(clearCart())
        }
    }, [createdInvoiceLoading])

    if (isInvoiceLoading || invoices.length === 0) {
        return <div>Loading...</div>;
    }

    const currentInvoice = invoices[currentInvoiceIndex];

    return (
        <div className="invoice-container">
            <div className="invoice-info-wrapper">
                <table className="invoice-info-table">
                    <tbody>
                        <tr>
                            <td>Invoice Number</td>
                            <td>{currentInvoice.InvoiceID}</td>
                        </tr>
                        <tr>
                            <td>Invoice Date</td>
                            <td>{currentInvoice.InvoiceDate}</td>
                        </tr>
                        <tr>
                            <td>Amount Due</td>
                            <td>{currentInvoice.total}</td>
                        </tr>
                        <tr>
                            <td>Paid?</td>
                            <td>{currentInvoice.HasPaid ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="invoice-items-container">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={1}>Product Name</th>
                            <th colSpan={2}>Product Description</th>
                            <th colSpan={1}>Product Price</th>
                            <th colSpan={1}>Product Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(currentInvoice.invoicelist) && currentInvoice.invoicelist.map((invoiceItem: any) => (
                            <tr key={invoiceItem.id}>
                                <td colSpan={1}>{invoiceItem.name} - {invoiceItem.ProductType}</td>
                                <td colSpan={2}>
                                    <div className="item-description-container">
                                        <span>{invoiceItem.desc}</span>
                                        <span>{invoiceItem.Colors}</span>
                                    </div>
                                </td>
                                <td colSpan={1}>{invoiceItem.ProductQuantity}</td>
                                <td colSpan={1}>{invoiceItem.ProductPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InvoicePage;
